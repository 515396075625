import type { ProjectMetricsValidator, ProjectValidator } from '@trustblock/validators/project.validators';
import type { AuditPublic, IssuesCount } from './audit.types';
import type { AuditorPublic } from './auditor.types';
import type { ContractPublicDeterministic, ContractType, ContractsCount } from './contract.types';
import {
  type DistributedOmit,
  type LinksPublic,
  type RequestResponseBase,
  type RequestResponsePagination,
  Tag
} from './global.types';

export interface ProjectsCount {
  total: number;
  [Tag.Analytics]: number;
  [Tag.Collectibles]: number;
  [Tag.Finance]: number;
  [Tag.Gaming]: number;
  [Tag.Other]: number;
  [Tag.Security]: number;
  [Tag.Social]: number;
  [Tag.Wallet]: number;
}

export type ProjectMetricsPrivate = ProjectMetricsValidator;
export type ProjectPrivate = Omit<ProjectValidator, 'createdAt'> & {
  tags: Tag[];
  links: LinksPublic;
  metrics: Omit<ProjectMetricsValidator, 'projectId'>;
  createdAt: number;
};
export type ProjectPublic = Omit<ProjectValidator, 'email' | 'id' | 'createdAt'> & {
  tags: Tag[];
  links: LinksPublic;
  issuesCount: IssuesCount;
  createdAt: number;
};

export enum ProjectWidgetColor {
  Light = 'light',
  Dark = 'dark'
}
export const projectWidgetColors = Object.values(ProjectWidgetColor);

export enum ProjectWidgetMode {
  Fit = 'fit',
  Fill = 'fill'
}
export const projectWidgetModes = Object.values(ProjectWidgetMode);

export type ProjectWidgetOptions = {
  color?: ProjectWidgetColor;
  mode?: ProjectWidgetMode;
  hideAuditNotFound?: boolean;
};

export enum ProjectWidgetErrorCode {
  ProjectNotFound = 'PROJECT_NOT_FOUND',
  InvalidRequest = 'INVALID_REQUEST'
}

export type GetProjectsSlugsResponse = RequestResponseBase & { items: string[] };

export type GetProjectSecurityStateResponseData = ProjectPublic & {
  hasCriticalIssuesNotFixed: boolean;
  auditedContractsOnChain: DistributedOmit<
    ContractPublicDeterministic<ContractType.OnChain>,
    'contractId' | 'type' | 'createdAt' | 'projectId'
  >[];
  audits: (DistributedOmit<AuditPublic, 'auditorId' | 'projectId'> & {
    auditor: Omit<AuditorPublic, 'metrics' | 'issuesCount' | 'projectsCount' | 'contractsCount'>;
  })[];
};
export type GetProjectSecurityStateResponse = RequestResponseBase & GetProjectSecurityStateResponseData;

export type SearchProjectsResponseItemData = Pick<ProjectPublic, 'domain' | 'name' | 'slug'> & {
  email: string;
  totalValue: number;
  lastAuditDate: number;
  lastAuditorName: string;
  hasCriticalIssuesNotFixed: boolean;
  contractsCount: ContractsCount;
  links: LinksPublic;
  tags: Tag[];
};
export type SearchProjectsResponse = RequestResponseBase & RequestResponsePagination<SearchProjectsResponseItemData>;

export type GetProjectPageDataResponseData = ProjectPublic & {
  audits: (Pick<AuditPublic, 'issuesCount' | 'name' | 'createdAt' | 'id'> & {
    contractsCount: ContractsCount;
    auditor: {
      slug: string;
      name: string;
    };
  })[];
};
export type GetProjectPageDataResponse = RequestResponseBase & GetProjectPageDataResponseData;

export interface GetProjectWidgetPageDataResponseData {
  slug: string;
  domain: string;
  lastAuditDate: number;
  hasCriticalIssuesNotFixed: boolean;
  lastAuditorName: string;
}
export type GetProjectWidgetPageDataResponse = RequestResponseBase & GetProjectWidgetPageDataResponseData;
