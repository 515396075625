import { fixedLengths, maxLengths } from '@trustblock/constants/configuration.constants';
import { IssueSeverity, IssueStatus } from '@trustblock/types/audit.types';
import { Link, type Tag, tags } from '@trustblock/types/global.types';
import { z } from 'zod';
import { testIsSimpleUrl, testSocialProfileLink } from './zodCustomValidators';

export function queryParamDate() {
  return z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date')
    .transform((date) => new Date(date))
    .refine((value) => value instanceof Date && !Number.isNaN(value.getTime()));
}

export function queryParamNumber(
  {
    isPositive = true,
    isInteger = true,
    includeZero = true
  }: {
    isPositive?: boolean;
    isInteger?: boolean;
    includeZero?: boolean;
  } = { isPositive: true, isInteger: true, includeZero: true }
) {
  let regexPattern: RegExp;

  if (isInteger) {
    if (isPositive) {
      regexPattern = includeZero ? /^(0|[1-9]\d*)$/ : /^[1-9]\d*$/;
    } else {
      regexPattern = /^-?\d+$/;
    }
  } else if (isPositive) {
    regexPattern = includeZero ? /^(0|[1-9]\d*(\.\d+)?)$/ : /^[1-9]\d*(\.\d+)?$/;
  } else {
    regexPattern = /^-?\d+(\.\d+)?$/;
  }

  return z
    .string()
    .regex(regexPattern, 'Invalid number')
    .transform(Number)
    .refine((value) => !Number.isNaN(value), { message: 'Invalid number' });
}

export function queryParamArray<Element extends string>({
  allowedValues,
  message
}: { allowedValues: Element[]; message: string }) {
  return z
    .string()
    .regex(new RegExp(`^(${allowedValues.join('|')})(,(${allowedValues.join('|')}))*$`), message)
    .transform((elements) => elements.split(',') as Element[])
    .refine(
      (elements) => elements.length > 0 && elements.every((element) => allowedValues.includes(element as Element)),
      {
        message
      }
    );
}

export function queryParamBoolean() {
  return z
    .string()
    .regex(/^(true|false)$/, 'Invalid boolean')
    .transform((value) => value === 'true');
}

export const linksValidator = z
  .object({
    targetId: z.string().uuid().length(fixedLengths.id),
    [Link.Website]: z.string().superRefine(testIsSimpleUrl)
  })
  .merge(
    z.object({
      [Link.Twitter]: z.string().optional().superRefine(testSocialProfileLink),
      [Link.Discord]: z.string().optional().superRefine(testSocialProfileLink),
      [Link.Telegram]: z.string().optional().superRefine(testSocialProfileLink),
      [Link.Github]: z.string().optional().superRefine(testSocialProfileLink),
      [Link.Youtube]: z.string().optional().superRefine(testSocialProfileLink),
      [Link.Linkedin]: z.string().optional().superRefine(testSocialProfileLink)
    })
  );

export type LinksValidator = z.infer<typeof linksValidator>;

export const tagsValidator = z.array(z.enum(tags as [Tag, ...Tag[]]));

export const paginationValidator = z.object({
  page: queryParamNumber()
});

export const issuesCountInnerValidator = z.object({
  total: z.number().int().nonnegative(),
  [IssueSeverity.Critical]: z.number().int().nonnegative().max(maxLengths.maxIssuesPerSeverity),
  [IssueSeverity.High]: z.number().int().nonnegative().max(maxLengths.maxIssuesPerSeverity),
  [IssueSeverity.Medium]: z.number().int().nonnegative().max(maxLengths.maxIssuesPerSeverity),
  [IssueSeverity.Low]: z.number().int().nonnegative().max(maxLengths.maxIssuesPerSeverity)
});

export const issuesCountValidator = z.object({
  total: z.number().int().nonnegative(),
  [IssueStatus.Fixed]: issuesCountInnerValidator,
  [IssueStatus.NotFixed]: issuesCountInnerValidator
});
