import { Chain } from '@trustblock/types/contract.types';
import { Tag } from '@trustblock/types/global.types';
import {
  createSearchParamsCache,
  parseAsArrayOf,
  parseAsInteger,
  parseAsString,
  parseAsStringEnum,
  parseAsStringLiteral
} from 'nuqs/server';

export const searchParser = {
  searchTerm: parseAsString.withDefault(''),
  page: parseAsString.withDefault('1'),
  tab: parseAsStringLiteral(['auditors', 'projects']).withDefault('projects'),
  chains: parseAsArrayOf(parseAsStringEnum<Chain>(Object.values(Chain))).withDefault([]),
  tags: parseAsArrayOf(parseAsStringEnum<Tag>(Object.values(Tag))).withDefault([]),
  tvs: parseAsInteger,
  name: parseAsString.withDefault('')
};

export const searchParamsCache = createSearchParamsCache(searchParser);
