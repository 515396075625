'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@trustblock/components/standalone/Button/Button';
import Form from '@trustblock/components/standalone/Form/Form';
import InputNumber from '@trustblock/components/standalone/Inputs/InputNumber/InputNumber';
import InputSelect from '@trustblock/components/standalone/Inputs/InputSelect/InputSelect';
import InputText from '@trustblock/components/standalone/Inputs/InputText/InputText';
import { chainsData } from '@trustblock/constants/contract.constants';
import { tagsData } from '@trustblock/constants/global.constants';
import { type Chain, chains as chainsOptions } from '@trustblock/types/contract.types';
import { searchAuditorsValidator } from '@trustblock/validators/auditor.validators';
import { tagsValidator } from '@trustblock/validators/global.validators';
import { useQueryStates } from 'nuqs';
import React, { useTransition } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { searchParser } from '../common';

const searchAuditorsLocalValidator = searchAuditorsValidator
  .extend({
    chains: z
      .array(z.enum(chainsOptions as [Chain, ...Chain[]]))
      .optional()
      .nullable(),
    tags: tagsValidator.optional().nullable(),
    tvs: z.number().positive().optional().nullable()
  })
  .omit({ page: true });
export type SearchAuditorsLocalParams = z.infer<typeof searchAuditorsLocalValidator>;

export function HomePageAuditorsForm() {
  const [isPending, startTransition] = useTransition();
  const [{ chains, tags, tvs, name }, setParams] = useQueryStates(searchParser, { startTransition });

  const formMethods = useForm<SearchAuditorsLocalParams>({
    resolver: zodResolver(searchAuditorsLocalValidator),
    defaultValues: {
      chains,
      tags,
      tvs: tvs ?? undefined,
      name
    }
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = formMethods;

  const search = handleSubmit((data) => {
    startTransition(() => {
      setParams(
        {
          chains: data.chains ?? [],
          tags: data.tags ?? [],
          tvs: data.tvs,
          name: data.name ?? ''
        },
        { history: 'push' }
      );
    });
  });

  return (
    <Form
      className="box-border items-start justify-center gap-4 cursor-pointer"
      onSubmit={search}
      formMethods={formMethods}
    >
      <div className="grid w-full gap-1 lg:grid-cols-[1fr_1fr_1fr__min-content_1fr_min-content] items-center">
        <InputSelect
          icon="Server"
          control={control}
          placeholder="Chains"
          fieldName="chains"
          isMulti
          options={chainsOptions.map((chain) => ({
            value: chain,
            label: chainsData[chain].label,
            icon: chainsData[chain].icon
          }))}
          error={errors?.chains?.message}
        />
        <InputSelect
          icon="Tags"
          control={control}
          placeholder="Tags"
          fieldName="tags"
          isMulti
          options={Object.values(tagsData)}
          error={errors?.tags?.message}
        />
        <InputNumber
          placeholder="Liquidity"
          icon="DollarSign"
          register={register}
          fieldName="tvs"
          onlyInteger
          onlyPositive
          error={errors?.tvs?.message}
          validationRules={undefined}
        />
        <div className="h-px lg:h-[70%] w-full lg:w-px bg-white107" />
        <InputText
          icon="Search"
          fieldName="name"
          placeholder="Name"
          register={register}
          error={errors?.name?.message}
          autoComplete="false"
          data-lpignore="true"
          data-form-type="other"
        />
        <Button onlyIcon icon="Search" type="submit" mode="primary" fill loading={isPending} />
      </div>
    </Form>
  );
}
