import React from 'react';
import type { FieldValues, RegisterOptions } from 'react-hook-form';
import InputText from '../InputText/InputText';
import type { InputProps } from '../input.types';

type InputNumberProps<T extends FieldValues> = {
  onlyInteger?: boolean;
  onlyPositive?: boolean;
  valueAsNumber?: boolean;
} & InputProps<T>;

function InputNumber<T extends FieldValues>({
  onlyInteger,
  onlyPositive = true,
  validationRules,
  defaultValue,
  valueAsNumber = true,
  readOnly,
  className,
  ...props
}: InputNumberProps<T>) {
  let extraProps = {};

  if (onlyInteger) {
    extraProps = {
      step: '1'
    };
  }

  if (onlyPositive) {
    extraProps = {
      ...extraProps,
      min: '0'
    };
  }

  return (
    <InputText
      readOnly={readOnly}
      className={className}
      type="number"
      defaultValue={defaultValue}
      validationRules={
        {
          setValueAs: valueAsNumber
            ? (value: unknown) => {
                if (value === undefined || value === null || value === '') {
                  return undefined;
                }
                return Number(value);
              }
            : undefined,
          ...validationRules
        } as RegisterOptions | undefined
      }
      {...extraProps}
      {...props}
    />
  );
}

export default InputNumber;
