import type { AuditIssueValidator, AuditValidator } from '@trustblock/validators/audit.validators';
import type { SearchAuditorsResponseItemData } from './auditor.types';
import type { ContractPublic } from './contract.types';
import type { CreateAuditErrorCode, CreateContractsErrorCode } from './error.types';
import type { DeepPartial, DistributedOmit, RequestResponseBase, Tag } from './global.types';
import type { ProjectPublic, SearchProjectsResponseItemData } from './project.types';

export enum IssueSeverity {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Critical = 'critical'
}
export const issueSeverities = Object.values(IssueSeverity);

export enum IssueStatus {
  Fixed = 'fixed',
  NotFixed = 'not_fixed'
}
export const issueStatuses = Object.values(IssueStatus);

export enum CreateAuditProjectMode {
  Assign = 'assign',
  Create = 'create'
}

export interface IssuesCount {
  total: number;
  [IssueStatus.Fixed]: {
    total: number;
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
  [IssueStatus.NotFixed]: {
    total: number;
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
}

export interface IssuesCountFromAI {
  [IssueStatus.Fixed]: {
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
  [IssueStatus.NotFixed]: {
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
}

export interface IssuesCountOptionalTotal {
  total?: number;
  [IssueStatus.Fixed]: {
    total?: number;
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
  [IssueStatus.NotFixed]: {
    total?: number;
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
}

export type AuditIssuePrivate = Omit<AuditIssueValidator, 'auditId'>;
export type AuditIssuePublic = Omit<AuditIssueValidator, 'auditId' | 'id'>;
export type AuditPrivate = Omit<AuditValidator, 'createdAt'> & {
  issues: AuditIssuePrivate[];
  createdAt: number;
};
export type AuditPublic = Omit<AuditValidator, 'createdAt'> & {
  issues: AuditIssuePublic[];
  issuesCount: IssuesCount;
  createdAt: number;
};

export type CreateAuditResponse = RequestResponseBase & {
  id: string;
};
export type CreateAuditResponseError = RequestResponseBase & {
  code: CreateAuditErrorCode | CreateContractsErrorCode;
};

export type GetAuditPageDataResponseData = Pick<
  AuditPublic,
  'createdAt' | 'description' | 'id' | 'issues' | 'issuesCount' | 'name' | 'reportFileCid' | 'reportFileUrl'
> & {
  project: SearchProjectsResponseItemData;
  auditor: SearchAuditorsResponseItemData;
  contracts: DistributedOmit<ContractPublic, 'createdAt' | 'id' | 'projectId' | 'metrics'>[];
};
export type GetAuditPageDataResponse = RequestResponseBase & GetAuditPageDataResponseData;

export type GetAuditsSlugsResponseItemData = {
  items: string[];
};

export type GetAuditsSlugsResponse = RequestResponseBase & GetAuditsSlugsResponseItemData;

export type ExtractAuditInfoResponseData = DeepPartial<Pick<AuditPublic, 'name' | 'description'>> & {
  project: DeepPartial<Pick<ProjectPublic, 'name' | 'links'>> & {
    tags?: Tag[];
  };
  issuesCount: IssuesCount;
};

export type ExtractAuditInfoResponse = RequestResponseBase & ExtractAuditInfoResponseData;
